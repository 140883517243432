// Get the imports
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  CollectionReference,
  collection,
  DocumentData,
} from "firebase/firestore";

import { getFunctions, httpsCallable } from "firebase/functions";
// import { ref, onUnmounted } from "vue";

// Init the firebase app
export const firebaseApp = initializeApp({
  apiKey: "AIzaSyD2y17484ypKXSSQ2lhzEOgTU-AiGrQryo",
  authDomain: "mingla.firebaseapp.com",
  projectId: "mingla",
  storageBucket: "mingla.appspot.com",
  messagingSenderId: "779957439783",
  appId: "1:779957439783:web:4f02b5a25fa799da8f2d02",
});

// Export firestore incase we need to access it directly
export const firestore = getFirestore();

// This is just a helper to add the type to the db responses
const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(firestore, collectionName) as CollectionReference<T>;
};

// Import all your model types
import { BusinessCard } from "src/types/BusinessCard";

// export all your collections
export const businessCardCollection =
  createCollection<BusinessCard>("businessCards");

// TODO: Move to separate file

export const unlockCard = async (
  cardId: string,
  unlockCode: string
): Promise<void> => {
  const functions = getFunctions(firebaseApp, "europe-west1");
  const unlockCard = httpsCallable(functions, "unlockCard");

  await unlockCard({
    cardId,
    code: unlockCode,
  });
};

interface WorkAddress {
  street: string;
  postalCode: string;
  city: string;
  countryRegion: string;
}

export interface BusinessCardRequest {
  cardId: string;
  code: string;
  firstName: string;
  lastName: string;
  organization: string;
  title: string;
  workAddress: WorkAddress;
  workEmail: string;
  workPhone: string;
  workUrl: string;
  linkedInUrl: string;
  imageBase64?: string;
}

export async function updateBusinessCard(
  cardDetails: BusinessCardRequest
): Promise<boolean> {
  const functions = getFunctions(firebaseApp, "europe-west1");
  const updateBusinessCard = httpsCallable(functions, "updateBusinessCard");

  try {
    await updateBusinessCard({
      ...cardDetails,
    });
  } catch (error) {
    console.error(error);
    return false;
  }
  return true;
}

interface IsCardWritable {
  isWritable: boolean;
}

interface IsCardWritableData {
  data: IsCardWritable;
}

export async function getIsCardWritable(
  cardId: string
): Promise<IsCardWritableData> {
  const functions = getFunctions(firebaseApp, "europe-west1");
  const getIsCardWritable: any = httpsCallable(functions, "getIsCardWritable");
  return await getIsCardWritable({ cardId });
}
