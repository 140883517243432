
import { defineComponent, reactive, toRefs } from "vue";
import "@/assets/checkmark-knight.png";

export default defineComponent({
  props: {
    modelValue: {
      required: true,
      type: String,
    },
    label: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
    },
    valid: {
      required: true,
      type: Boolean,
    },
    uppercased: {
      required: false,
      type: Boolean,
      default: false,
    },
    showSuccess: {
      required: false,
      type: Boolean,
      default: true,
    },
    maxLength: {
      required: false,
      type: Number,
    },
    passwordEntry: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "blur"],
  methods: {
    updateValue(event: any) {
      if (this.passwordEntry) {
        this.$emit("update:modelValue", event.target.value);
      }
    },
    blur(event: any) {
      this.$emit("update:modelValue", event.target.value);
      this.$emit("blur");
    },
  },
  setup() {
    return {
      ...toRefs(
        reactive({
          someThing: "Hello",
        })
      ),
    };
  },
});
